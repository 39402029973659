import {
  SIGNIN_ERROR,
  SIGNIN_SUCCESS,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  SIGNOUT_SUCCESS,
  FORM_DISABLED,
  UPDATE_PROPS,
  UPDATE_ROLE
} from '../actions/actionTypes'
import { getFromLocalStorage } from '../../shared/shared'
import axios from 'axios'
import { setHeaders } from '../../shared/shared'


const initialState = {
  email: getFromLocalStorage('email') ? getFromLocalStorage('email') : '',
  isLoggedIn: !!getFromLocalStorage('token'),
  authError: null,
  formDisabled: false,
  role: '',
  userId: getFromLocalStorage('userId') ? getFromLocalStorage('userId') : '',
  registered: getFromLocalStorage('registered') === "true" ? true : false
}
//console.og(initialState)


const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_ERROR:
      //console.og('error signing up user')
      return {
        ...state,
        authError: action.err.message
      }

    case SIGNUP_SUCCESS:
      //console.og('successfully signed up new participant')
      //console.og(action)
      return {
        ...state,
        authError: null,
        // email: action.res.data.user.email,
        // isLoggedIn: true,
        // userData: action.res.data.user,
        // userId: action.res.data.user._id,
        // registered: action.res.data.user.isTeamRegistered === true ? true : false
      }

    case SIGNOUT_SUCCESS:
      //console.log('successfully logged you out')
      return {
        ...state,
        authError: null,
        email: '',
        isLoggedIn: false,
        userId: "",
        registered: false,
        formDisabled: false,
        role: '',
      }

    case SIGNIN_ERROR:
      //console.log('login failed')
      return {
        ...state,
        authError: action.err.message
      }

    case SIGNIN_SUCCESS:
      //console.log('login success')
      //console.log(action.res)
      return {
        ...state,
        authError: null,
        email: action.res.data.user.email,
        isLoggedIn: true,
        userData: action.res.data.user,
        userId: action.res.data.user._id,
        role: action.res.data.user.role,
        registered: action.res.data.user.isTeamRegistered === true ? true : false
      }


    case UPDATE_ROLE:
      return {
        ...state,
        role: action.role
      }

    case FORM_DISABLED:
      //console.log(action.type)
      return {
        ...state,
        formDisabled: true
      }

    case UPDATE_PROPS:
      return {
        ...state,
        registered: true
      }

    default:
      return state
  }
}

export default authReducer
