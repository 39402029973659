import React, { Component } from "react";
import { withRouter, Link, NavLink as RouterNavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink
} from 'reactstrap';
import Proptypes from 'prop-types'


import NavItemLink from './NavItemLink'
import { checkFormDisabled, signOut, updateRole } from "../../../store/actions/authActions";
import { getFromLocalStorage } from "../../../shared/shared";


class MainNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false // Initially collapsed
    };
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      isCollapsed: !prevState.isCollapsed
    }));
  };

  signOut = () => { this.props.logout(this.props.history) }


  static getDerivedStateFromProps(nextProps, prevState) {
    nextProps.checkRegistrationClosed()
    return null;
  }

  componentDidMount() {
    if (!this.props.role) {
      if(this.props.userId || getFromLocalStorage('userId')){
        this.props.updateRole(this.props.userId || getFromLocalStorage('userId'))
      }
    }
    // console.log('checking ')
    // this.props.checkRegistrationClosed()
  }

  render() {
    console.log(this.props)
    let { location } = this.props
    let pathname = location.pathname
    let { isCollapsed } = this.state
    let avatar = this.props.email ? this.props.email : null;
    if (avatar !== null) {
      avatar = avatar.charAt(0).toUpperCase();
    }
    let registerBtnClass = this.props.isRegistrationClosed ? "btn btn-outline-success" : "btn btn-outline-success btn-disabled"
    let registerBtnDisabled = !!this.props.isRegistrationClosed
    return (
      <div>
        <header className="header">
          <Navbar expand="lg">
            <NavbarBrand className="brand-logo" to="/" tag={Link}>
              <img src="assets/images/logowhite.png" alt="Spirathon" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleCollapse} className="me-2">
              <i className="fa fa-bars" aria-hidden="true"></i>
            </NavbarToggler>
            <Collapse isOpen={isCollapsed} navbar id="navbarSupportedContent">
              <Nav className="me-auto mb-2 mb-lg-0" navbar>
                <NavItemLink to="/about" pathname={pathname} >About</NavItemLink>
                <NavItemLink to="/spiralogics" pathname={pathname} >Spiralogics</NavItemLink>
                <NavItemLink to="/spirathon24" pathname={pathname} >Spirathon 2024</NavItemLink>
                <NavItemLink to="/how-to-join" pathname={pathname} >How to Join?</NavItemLink>
                <NavItemLink to="/contact-us" pathname={pathname} >Contact Us</NavItemLink>
                {/* {
                  this.props.isLoggedIn &&
                  <NavItemLink to="/myteam" pathname={pathname} >My Team</NavItemLink>
                }
                {
                  (this.props.isLoggedIn && this.props.role == 'admin') &&
                  <NavItemLink to="/winnerSelection" pathname={pathname} >Winner Selection</NavItemLink>
                } */}
              </Nav>
              {/* {
                !this.props.isLoggedIn &&
                <>
                  <form className="d-flex mx-lg-2">
                    <RouterNavLink to="/signin" >
                      <button className="btn btn-outline-success" type="submit" >Login</button>
                    </RouterNavLink>
                  </form>
                  <form className="d-flex">
                    {
                      this.props.isRegistrationClosed ?
                        <button className="btn btn-outline-success btn-disabled" type="submit" disabled={true}>Register</button>
                        :
                        <RouterNavLink to="/signup" >
                          <button className="btn btn-outline-success" type="submit" disabled={false}>Register</button>
                        </RouterNavLink>
                    }
                  </form>
                </>
              }
              {
                this.props.isLoggedIn &&
                <button className="btn btn-outline-success" type="submit" onClick={this.signOut}>Logout</button>
              } */}

              <form className="d-flex">
               <button className="btn btn-outline-success btn-disabled" type="submit" disabled={true}>Register</button>
              </form>
            </Collapse>
          </Navbar>
        </header>
      </div>
    );
  }
}

MainNavbar.propTypes = {
  logout: Proptypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    email: state.auth.email,
    isLoggedIn: state.auth.isLoggedIn,
    isRegistrationClosed: state.auth.formDisabled,
    role: state.auth.role
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: (history) => dispatch(signOut(history)),
    checkRegistrationClosed: () => dispatch(checkFormDisabled()),
    updateRole: userId => dispatch(updateRole(userId))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNavbar));
