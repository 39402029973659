import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import UserNavLink from './UserNavlink';

class RulesPage extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    if (!this.props.isLoggedIn) return <Redirect to='/signin' />
    else {
      return (
        <>
          <div className="announcing-wrapper container">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-md-6">
                  <div className="top-part__header">
                    <span>Rules</span>
                    <h2>Rules </h2>
                    <br />
                  </div>
                </div>
              </div>
              <UserNavLink />
              <div className='col'>
                <div>
                  <h2>Rules/Entry Requirement:</h2>
                  <ol className="">
                    <li>
                      The contest is open to the public.
                    </li>
                    <li>
                      Participants may only join one team.
                    </li>
                    <li>
                      Teams will be required to elect a team lead or team representative who will act as a contact point with the Spirathon organizers.
                    </li>
                    <li>
                      Judges’ decisions are final and are not subject to review.
                    </li>
                    <li>
                      The applicant(signed up user) should only register one team.
                    </li>
                    <li>
                      Participants must select dataset from a specific sector of Nepal, identify a prevailing problem around that sector and develop an app as a solution.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

export default connect(mapStateToProps, null)(RulesPage)
