import React, { Component } from "react";

export const Spiralogics = () => {
  return (
    <>
      <section className="announcing-wrapper my-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="top-part">
                <div className="top-part__header">
                  <span>Spiralogics </span>
                  <h2>Spiralogics </h2>
                </div> {/* section-header */}
              </div>
              <p>
                Spiralogics, established in 2002 in America and 2009 in Nepal, is a leading custom software development company. 
                We are an equal opportunity provider, focusing on an engaging and inclusive work culture. With a team of 80+ members in different parts of the world like USA, Nepal, India, Phillippines, etc., we have completed over 2500 projects. 
                Over the past two decades, our goal has consistently been to impart the knowledge we've accumulated to aspiring developers entering the field.
              </p>
            </div> {/* col */}
            <div className="col-md-6  d-flex align-items-center">
              <div className="about-image">
                <img src="assets/images/spiralogics.svg" alt="" />
              </div>
            </div> {/* col */}
          </div> {/* row */}
        </div>
        <section className="outreach">
          <div className="container-fluid">
            <div className="mid-part">
              <div className="mid-part__header">
                <h2>Engagement &amp; Outreach</h2>
                <div className="lines" />
              </div>
              <div className="row">
                <div className="col-12 ">
                  <p className="outreach-tilte-description">
                    Spiralogics has been conducting various events with a focus on fostering robust organization engagement and cultivating a thriving community within our workplace. Through initiatives such as Knowledge Sharing Sessions, Tech Talks, and Workshops, we aim to facilitate continuous learning, collaboration, and skill development among our employees. Here are some of our previous initiatives:
                  </p>
                </div>
              </div>
              <div className="outreach-content">
                <div className="outreach-content__items">
                  <h3>Knowledge Sharing Sessions</h3>
                  <p>We organize regular sessions where our team share their expertise, experiences, and insights with undergrad students. These are done in various formats such as presentations, panel discussions, or interactive Q&amp;A sessions. These sessions encourage participation and create an open environment for knowledge exchange. </p>
                </div>
                <div className="outreach-content__image">
                  <img src="assets/images/spiralogic/Knowledge Sharing Sessions.svg" alt="outreach-images" />
                </div>
              </div>
              <div className="outreach-content">
                <div className="outreach-content__items">
                  <h3>Tech Talks </h3>
                  <p>We invite experts from the technology field to speak on relevant and trending topics. It covers a range of subjects, from emerging technologies to best practices in the industry.  This allows for networking opportunities and discussions among participants.</p>
                </div>
                <div className="outreach-content__image">
                  <img src="assets/images/spiralogic/Tech Talks.svg" alt="outreach-images" />
                </div>
              </div>
              <div className="outreach-content">
                <div className="outreach-content__items">
                  <h3>Workshops </h3>
                  <p>We develop hands-on, practical sessions to enhance the skills and knowledge of participants. Each workshop is tailored to address specific challenges based in real-life. This encourages collaboration and provides a platform for skill development of participants.</p>
                </div>
                <div className="outreach-content__image">
                  <img src="assets/images/spiralogic/Workshops.svg" alt="outreach-images" />
                </div>
              </div>
              <div className="outreach-content">
                <div className="outreach-content__items">
                  <h3>Corporate Giving Program </h3>
                  <p>Our Corporate Giving Program underscores our commitment to social responsibility, encouraging active participation in initiatives that positively impact society. We identify local charities, community projects, or initiatives that align with the company's values and encourage employee participation through volunteer programs or donation drives.</p>
                </div>
                <div className="outreach-content__image">
                  <img src="assets/images/spiralogic/Corporate Giving Program .svg" alt="outreach-images" />
                </div>
              </div>
              <div className="outreach-content">
                <div className="outreach-content__items">
                  <h3>MOUs</h3>
                  <p>The signing of Memorandums of Understanding (MOUs) with strategic partners reflects our dedication to establishing meaningful collaborations that drive innovation and shared success.</p>
                </div>
                <div className="outreach-content__image">
                  <img src="assets/images/spiralogic/MOUs .svg" alt="outreach-images" />
                </div>
              </div>
            </div>
          </div>
        </section>{/*outreach*/}
      </section> {/* spiralogics */}
    </>
  )
}