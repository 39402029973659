import {
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  SIGNOUT_SUCCESS,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  FORM_DISABLED,
  UPDATE_ROLE
} from './actionTypes'
import { baseUrl } from '../../config/apiUrls'
import { disableForm, setHeaders } from '../../shared/shared'
import axios from 'axios'
import { toast } from 'react-toastify'

// for register
export const signUp = (newParticipant, history) => {
  return async (dispatch, getState) => {
    try {
      const res = await axios.post(`${baseUrl}createAuthUser/`, newParticipant)
      //console.og(res)
      if (res.data.success == false) {
        toast.error(res.data.message)
        dispatch(signupFailure(res))
      } else {
        toast.success("Sign Up Successful!")
        // redirect to login page rather than signing in automatically
        clearLocalStorage()
        history.push('/signin')
        // dispatch(signupSuccess(res))
      }
    } catch (err) {
      console.error(err)
      toast.error("Sign Up Failed!")
      dispatch(signupFailure(err))
    }
  }
}


export function updateRole(userId) {
  return async (dispatch, getState) => {
    try {
      const res = await axios.get(`${baseUrl}v1/users/${userId}`,  { headers: setHeaders() })
      // console.log(res.data.data.role)
      if (res.data.success === true) {
        if(res.data) {
          let userRole = res.data.data.role || 'user'
          dispatch(updateStateRole(userRole))
        }
      } else {
        console.log('cannot access role')
        // add servermessage here
        // toast.error(res.data.message)
        // dispatch(signinFailure(res.data.message))
      }
    } catch (err) {
      console.log(err)
      // dispatch(signinFailure(err))
    }
  }
}

function updateStateRole(role) {
  return {
    type: UPDATE_ROLE,
    role
  }
}

function signupSuccess(res) {
  return {
    type: SIGNUP_SUCCESS,
    res
  }
}

function signupFailure(err) {
  return {
    type: SIGNUP_ERROR,
    err
  }
}

// for login
export const signIn = credentials => {
  //console.og(credentials)
  return async (dispatch, getState) => {
    try {
      const res = await axios.post(`${baseUrl}authenticate/`, credentials)
      //console.og(res)
      if (res.data.success === true) {
        toast.success("Sign In Successful!")
        console.log(res.data)
        setLocalStorage(res.data)
        dispatch(signinSuccess(res))
      } else {
        // add servermessage here
        toast.error(res.data.message)
        dispatch(signinFailure(res.data.message))
      }
    } catch (err) {
      //console.log(err.config)
      dispatch(signinFailure(err))
    }
  }
}


function signinSuccess(res) {
  return {
    type: SIGNIN_SUCCESS,
    res
  }
}

function signinFailure(err) {
  return {
    type: SIGNIN_ERROR,
    err
  }
}

function setLocalStorage(data) {
  //console.og(data)
  localStorage.setItem('email', data.user.email)
  localStorage.setItem('token', data.token)
  localStorage.setItem('userId', data.user._id)
  localStorage.setItem('registered', data.user.isTeamRegistered)
localStorage.setItem('role',data.user.role)
}

// for logout
export const signOut = (history) => {
  return (dispatch, getState) => {
    clearLocalStorage()
    history.push('/signin')
    dispatch(checkFormDisabled())
    dispatch(signoutSuccess())
  }
}
function signoutSuccess() {
  return {
    type: SIGNOUT_SUCCESS
  }
}

function clearLocalStorage() {
  localStorage.clear()
}

// for disableForm

export const checkFormDisabled = (userId = null) => {
  return async (dispatch, getState) => {
    try {
      if (userId) {
        const res = await axios.get(`${baseUrl}v1/users/${userId}`, { headers: setHeaders() })
        let data = res.data //data = response object
        if (data.success === true) {
          if (data.data) {
            if (data.data.isTeamRegistered || data.data.registrationStatus == "complete") {
              dispatch(disableFormSuccess())
            }
          }
        }
      }
      let isRegistrationClosed = await disableForm()
      if (isRegistrationClosed) {
        dispatch(disableFormSuccess())
      }
    } catch (error) {
      let isRegistrationClosed = await disableForm()
      if (isRegistrationClosed) {
        dispatch(disableFormSuccess())
      }
    }
  }
}

function disableFormSuccess() {
  return {
    type: FORM_DISABLED
  }
}
