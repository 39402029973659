import React, { Component } from "react";
import SignedOutLinks from "./SignedOutFooter";

class CountDown extends Component {
  state = {
    days: "0",
    hours: "0",
    minutes: "0",
    seconds: "0"
  };

  callToDisable() {
    if (
      this.state.days === 0 &&
      this.state.hours === 0 &&
      this.state.minutes === 0 &&
      this.state.seconds === 0
    ) {
      return true;
    } else return false;
  }

  componentDidMount() {
    var countDownDate = new Date("Feb 2, 2019 10:30:00").getTime();
    this.interval = setInterval(() => {
      // Get todays date and time
      let now = new Date().getTime();
      // Find the distance between now and the count down date
      let distance = countDownDate - now;
      if (countDownDate - now > 0) {
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        if (minutes < 10) {
          minutes = `0${minutes}`;
        } else {
          minutes = minutes;
        }
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if (seconds < 10) {
          seconds = `0${seconds}`;
        } else {
          seconds = seconds;
        }
        this.setState({
          days,
          hours,
          minutes,
          seconds
        });
      } else {
        this.setState({
          state: {
            days: "0",
            hours: "0",
            minutes: "0",
            seconds: "0"
          }
        });
      }
    }, 1000);
  }

  render() {
    return (
      <div className="col col-3-4 col-sm-1_1 col-pt-1_1 clearfix">
        <div className="col col-1-4 col-sm-1_1 col-pt-1_1 clearfix">
          <h6>AI Conference starts in</h6>
        </div>
        <div className="col-col-3-4 col-sm-1_1 col-pt-1_1 clearfix">
          <div className="data-box">
            <label>Days</label>
            <div className="content-box">
              <h4>{this.state.days}</h4>
            </div>
          </div>
          <div className="data-box">
            <label>Hours</label>
            <div className="content-box">
              <h4>{this.state.hours}</h4>
            </div>
          </div>
          <div className="data-box">
            <h4>:</h4>
          </div>
          <div className="data-box">
            <label>Minutes</label>
            <div className="content-box">
              <h4>{this.state.minutes}</h4>
            </div>
          </div>
          <div className="data-box">
            <h4>:</h4>
          </div>
          <div className="data-box">
            <label>Seconds</label>
            <div className="content-box">
              <h4>{this.state.seconds}</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // componentWillUnmount () {
  //   clearInterval(this.interval).bind(this)
  // }
}

export default CountDown;
