import React, { Component } from 'react'

class HowToJoin extends Component {

  render() {
    return (
      <section className="announcing-wrapper my-5">
        <div className="container-fluid">
          <div className="inner-page-block">
            <div className="row">
              <div className="col-md-6">
                <div className="top-part">
                  <div className="top-part__header">
                    <span>How to Participate in Spirathon? </span>
                    <h2>How to Participate in Spirathon?</h2>
                  </div> {/* section-header */}
                </div>
              </div> {/* col */}
            </div> {/* row */}
          </div>
          <div className="how-to-participate">
          <ul className=" participate-content">
              <li className="point-1"> <span className="highlight">1</span> You will need to register your team through the register button on the top right. This will lead you to sign up page, where you will need to sign up with your Name, Email Address and Phone Number. </li>
              <li className="point-2"><span className="highlight">2</span> After signing up, log in with your email address and password. You will then be redirected to the registration form. </li>
              <li className="point-3"> <span className="highlight">3</span> Submit your team picture, team’s name, team members name, project title, project overview, associated institution/ college, member’s email and phone number. Please use a valid email or your participation will be rejected.</li>
              <li className="point-4"> <span className="highlight">4</span> Our internal selection committee will choose the top 10 teams to present their ideas in the preliminary round.</li>
              <li className="point-5"><span className="highlight">5</span> The team-lead will need to visit Spiralogics International main office in Shankamul Tower. The selected team will be called in for orientation.</li>
          </ul>
          </div>
        </div>
      </section>
    )
  }
}

export default HowToJoin
