import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { baseUrl } from '../../config/apiUrls'

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errors: {}
        };
    }


    validateForm = () => {
        const { email, password } = this.state;
        const errors = {};

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            if (!email) {
                errors.email = 'Please Enter Email'
              }
              else {
                errors.email = 'Please Enter Valid Email';
              }
        }

        // Password validation
        if (!password) {
            errors.password = 'Please Enter Password.';
        }

        this.setState({ errors });
        return Object.keys(errors).length === 0;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            this.props.signin(this.state)
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };

    render() {
        // remove later
        return <Redirect to={''} />

        const { email, password, errors } = this.state;
        if (this.props.authError) return <div>{this.props.authError}</div>
        if (this.props.isLoggedIn) return <Redirect to='/myteam' />
        return (
            <section className="announcing-wrapper container">
                <div className="container-fluid">
                    <div className="inner-page-block">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="top-part__header">
                                    <span>Login </span>
                                    <h2>Login</h2>
                                    <br />
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="form-sec form-sec--spirathon2024 pb-3">
                                        <form onSubmit={this.handleSubmit}>
                                            <ul>
                                                <li>
                                                    <input
                                                        type="email"
                                                        className={`${errors.email && 'is-invalid'}`}
                                                        id="email"
                                                        placeholder="Enter email"
                                                        value={email}
                                                        onChange={this.handleChange}
                                                    />
                                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                                </li>
                                                <li>
                                                    <input
                                                        type="password"
                                                        className={`${errors.password && 'is-invalid'}`}
                                                        id="password"
                                                        placeholder="Enter password"
                                                        value={password}
                                                        onChange={this.handleChange}
                                                    />
                                                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                                </li>
                                                <li>
                                                    <button className='common-btn w-100'>Login</button>
                                                </li>
                                                {errors.error && <div className='invalid-feedback'>{errors.error}</div>}
                                            </ul>
                                        </form>
                                        <div className='row'>
                                            <div className='mt-2 col-lg-6 '>
                                                {/* Haven't signed up yet? &nbsp;
                                                <NavLink className="simple-link" to='/signup'>
                                                    Signup
                                                </NavLink> */}
                                            </div>
                                            <div className='mt-2 mb-2 col-lg-6 text-lg-end '>
                                                <a href={`${baseUrl}forgot`} className="simple-link" target="_blank">
                                                    Forgot Password?
                                                </a>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

SignIn.propTypes = {
    signin: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    //console.og(state)
    return {
        email: state.auth.email,
        authError: state.auth.authError,
        isLoggedIn: state.auth.isLoggedIn
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signin: userInfo => dispatch(signIn(userInfo))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
