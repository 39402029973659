import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { getFromLocalStorage } from '../../shared/shared'

class RegisteredNavLink extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let id = getFromLocalStorage('userId');
    //console.og(`/team-view/${this.props.id}`)
    return (
      <ul className='bottomnav'>
        <li>
          <NavLink to='/rules'>Rules</NavLink>
          {/* <NavLink to='/opponents'>Opponents</NavLink> */}
          <NavLink to={`/team-view/${this.props.id}`}>View</NavLink>
        </li>
      </ul>
    )
  }
}

const mapStateToProps = state => {
  return {
    isRegistered: state.auth.registered,
    id: state.auth.userId
  }
}

export default connect(mapStateToProps)(RegisteredNavLink)
