import React, { Component } from "react";
// import './App.css'
import {  Route, Redirect, Switch, HashRouter } from "react-router-dom";

// import Navbar from "./components/layouts/Navbar";
// import Snackbar from "./components/layouts/snackbar.js";
// import Login from "./components/auth/Login";
// import MentorsPage from "./components/layouts/MentorsPage";
// import FinalistsPage from "./components/layouts/Finalistspage";
// import SpeakersPage from "./components/layouts/SpeakersPage";
// import PrizesPage from "./components/layouts/PrizesPage";
// import WorkShop from "./components/layouts/WorkshopPage";
// import Steps from "./components/layouts/StepsPage";
// import Dashboard from "./components/user/Dashboard.js";
// import TeamView from "./components/user/TeamView.js";
// import Opponent from "./components/user/Opponent.js";
// import Rules from "./components/user/Rules.js";
// import ComingSoon from './components/layouts/ComingSoon'
// import View from "./components/layouts/View";
// import ViewerPage from "./components/layouts/Viewerpage";
// import Loader from "./components/layouts/loader";
// import Ticketing from "./components/layouts/Ticketpage";
// import Conference from "./components/layouts/Conferencepage";
// import UserNavLink from "./components/user/UserNavlink";
import MainFooter from "./components/Footer/MainFooter";
import NotificationComponent from "./components/layouts/Notification";
import ReactGA from "react-ga";


//  spirathon 2024

import SignUp from "./components/auth/SignUp.js";
import SignIn from "./components/auth/SignIn.js";
import About from "./components/pages/About.js";
import HomePage from "./components/pages/Home.js";
import NotFound from "./components/pages/NotFound.js";
import Spirathon24 from "./components/pages/Spirathon24.js";
import { Spiralogics } from "./components/pages/Spiralogics.js";
import GetInTouch from "./components/pages/GetInTouch.js";
import MainNavbar from "./components/layouts/Header/MainNavbar.js";
import HowToJoin from "./components/pages/HowToJoin.js";
import 'react-image-lightbox/style.css';
import Rules from "./components/user/Rules.js";
import TeamEdit from "./components/user/TeamEdit";
import MyTeam from "./components/user/MyTeam";
import WinnerSelection from "./components/pages/WinnerSelection.js";




class App extends Component {
  componentDidMount() {
    // this.initializeReactGA();
  }

  initializeReactGA() {
    ReactGA.initialize("UA-130301796-1");
    ReactGA.pageview("/viewer");
  }

  render() {
    return (
      <HashRouter>
        <React.Fragment>
          <MainNavbar/>
          {/* Below is old navbar component */}
          {/* <Navbar /> */}
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/signin" component={SignIn} />
            <Route path="/signup" component={SignUp} />
            <Route path="/about" component={About} />
            <Route path="/spiralogics" component={Spiralogics} />
            <Route path="/spirathon24" component={Spirathon24} />
            <Route path="/contact-us" component={GetInTouch} />
            <Route path="/how-to-join" component={HowToJoin} />
            <Route path="/not-found" component={NotFound} />
            {/* private routes */}
            {/* <Route exact path="/team/edit/:id" component={TeamEdit} /> */}
            {/* <Route path="/rules" component={Rules} /> */}
            <Route path="/myteam" component={MyTeam} />
            <Route path="/winnerSelection" component={WinnerSelection} />


            {/* old pages need to filter out which are needed */}
            {/* <Route path="/mentors" component={MentorsPage} />
            <Route path="/finalists" component={FinalistsPage} />
            <Route path="/speakers" component={SpeakersPage} />
            <Route path="/prizes" component={PrizesPage} />
            <Route path="/workshop" component={WorkShop} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/opponents" component={Opponent} />
            <Route path="/viewer" component={ViewerPage} />
            <Route path="/details/:id" component={View} />
            <Route path="/team-view/:id" component={TeamView} />
            <Route path="/know-your-opponents" component={Opponent} />
            <Route path="/ticketing/:id" component={Ticketing} />
            <Route path="/loader" component={Loader} />
          <Route path="/conference" component={Conference} /> */}
            <Redirect from="*" to="/not-found" />
          </Switch>
          <MainFooter />
          {/* <Snackbar /> */}
          <NotificationComponent />
        </React.Fragment>
      </HashRouter>
    );
  }
}

export default App;
