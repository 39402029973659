import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';


class About extends Component {
  constructor() {
    super()
    this.state = {
      memories: [],
      photoIndex: 0,
      isOpen: false
    }
  }

  componentDidMount() {
    let memoriesTemp = []
    for (let i = 1; i <= 40; i++) {
      memoriesTemp.push({
        title: '',
        url: `assets/images/${i}.jpg`
      })
    }
    this.setMemories(memoriesTemp)
  }

  setMemories(memories = []) {
    if (memories.length != 0) {
      this.setState({
        memories
      })
    }

  }

  lightboxHandler(e, index) {
    this.setState({
      isOpen: true,
      photoIndex: index
    })
  }

  render() {
    const { isOpen, memories, photoIndex } = this.state
    return (
      <>
        <section className="announcing-wrapper my-5">
          <div className="container-fluid">
            <div >
              <div className="row">
                <div className="col-md-6">
                  <div className="top-part">
                    <div className="top-part__header">
                      <span>SPIRATHON</span>
                      <h2>SPIRATHON</h2>
                    </div> {/* section-header */}
                    {/* <p class="top-part__description"> We are glad to be back with our flagship event ‘SPIRATHON’!  </p> */}
                  </div>
                  <p>
                    Spirathon is an annual hackathon where a team consisting of a maximum of 3 members will need to turn the
                    raw idea, they have into a real-life computer application. The team can build their application in any
                    computer language and platform. Spiralogics as well as other high profile software companies will be
                    providing mentorship to each of the team. Professionals from the top IT companies and non-government
                    organizations will be judging the event and choosing the winners.
                  </p>
                </div> {/* col */}
                <div className="col-md-6">
                  <div className="about-image">
                    <img src="assets/images/about.svg" alt="" />
                  </div>
                </div> {/* col */}
              </div> {/* row */}
              <div className="objective mt-5">{/*objectives*/}
                <div className="top-part">
                  <div className="top-part__header">
                    <span>Objectives</span>
                    <h2>Objectives</h2>
                  </div> {/* section-header */}
                  <div>
                    <div className="d-flex objectives">
                      <div className="objective-icon">
                        <img src="assets/images/tick.svg" alt="icon" />
                      </div>
                      <p>To promote interaction between the IT professionals (both from within Spiralogics and outside) and
                        students participating in the event.</p>
                    </div>
                    <div className="d-flex objectives">
                      <div className="objective-icon">
                        <img src="assets/images/tick.svg" alt="icon" />
                      </div>
                      <p>To assist students in enhancing their programming skills as programming has become increasingly essential in this advancing world. </p>
                    </div>
                    <div className="d-flex objectives">
                      <div className="objective-icon">
                        <img src="assets/images/tick.svg" alt="icon" />
                      </div>
                      <p>To motivate students to participate in events where they can showcase and cultivate their talents in front of leading industry professionals and investors.</p>
                    </div>
                    <div className="d-flex objectives">
                      <div className="objective-icon">
                        <img src="assets/images/tick.svg" alt="icon" />
                      </div>
                      <p>To encourage students to contribute towards their society and country through their programming skills.  </p>
                    </div>
                    <div className="d-flex objectives">
                      <div className="objective-icon">
                        <img src="assets/images/tick.svg" alt="icon" />
                      </div>
                      <p>To offer a programmer-friendly platform and promote coding culture in colleges.  </p>
                    </div>
                    <div className="d-flex objectives">
                      <div className="objective-icon">
                        <img src="assets/images/tick.svg" alt="icon" />
                      </div>
                      <p>To encourage interaction among IT professionals, including those from Spiralogics and external participants, and students engaged in the event.  </p>
                    </div>
                    <div className="d-flex objectives">
                      <div className="objective-icon">
                        <img src="assets/images/tick.svg" alt="icon" />
                      </div>
                      <p>To rediscover the possibilities technology can create in our daily lives. </p>
                    </div>
                  </div>
                </div>
              </div>{/*objectives*/}
              <div className="history mt-5">
                <div className="top-part">
                  <div className="top-part__header">
                    <span>History</span>
                    <h2>History</h2>
                  </div> {/* section-header */}
                  <p>
                    Our first Spirathon held in 2017 themed, ‘Code for Society’ had participants build a prototype application
                    which would create a positive impact in society. Alina Devkota, Spandan Pyakurel and Saloni Sikha of Team
                    Aarakshya, won the first Spirathon with their project “MeriSaathi”, a comprehensive mobile app for women
                    safety. <br/><br/>
                    Team Night Owls with their project ‘Satyata’ won our second Spirathon held in 2019 which was themed ‘AI for Impact’. <br/> <br/>
                    
                    Our third Spirathon held in 2020 themed, ‘TechXperience Nepal’ which was based on Tourism since 2020 was
                    marked as the national tourism year of Nepal starting "Visit Nepal 2020" campaign. Team Fsociety which
                    consisted of two members, i.e., Mitesh Pandey &amp; Alex Bhattarai were the winners of Spirathon 2020. Their
                    project was named travelChain which introduced gamification in the tourism industry along with
                    transparency and trust of blockchain and smartness of artificial intelligence.
                  </p>
                  <div className="time-line">
                    <ul>
                      <li>
                        <div className="time-line__top">
                          <span className="number">
                            2017
                          </span>
                          <span className="line" />
                        </div>
                        <div className="top-line__bottom">
                          <span className="details">
                            Spirathon 2017 <br />
                            Code for Society
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="time-line__top">
                          <span className="details">
                            Spirathon 2019
                          </span>
                        </div>
                        <div className="top-line__bottom">
                          <span className="number">
                            2019 <br />
                            AI for Impact
                          </span>
                          <span className="line" />
                        </div>
                      </li>
                      <li>
                        <div className="time-line__top">
                          <span className="number">
                            2020
                          </span>
                          <span className="line" />
                        </div>
                        <div className="top-line__bottom">
                          <span className="details">
                            Spirathon 2020 <br />
                            TechXperience Nepal
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> {/* top-part */}
              </div> {/* history*/}
              <div className="gallery">
                <div className="mid-part">
                  <div className="mid-part__header">
                    <h2>Flashback Moments</h2>
                    <div className="lines" />
                  </div>
                  <div className="gallery-images">
                    <div className="container">
                      <div className="row gallerys">
                        {
                          memories.map((memory, index) => {
                            if (!memory.url) {
                              return
                            }
                            return (<div className="col-md-3" key={Math.random()}>
                              <img src={memory.url} alt="gallery-img" className="img-fluid" style={{ objectPosition: ' 50% 25%' }} onClick={(e) => this.lightboxHandler(e, index)} />
                            </div>)
                          })
                        }
                        {/* <a href={memory.url} target="_blank"><img src={memory.url} alt="gallery-img" className="img-fluid" /></a> */}
                      </div>
                    </div>
                  </div>
                </div> {/* section-header */}
              </div>{/*gallery*/}
              <div className="judges">
                <div className="mid-part">
                  <div className="mid-part__header">
                    <h2>Past Judges</h2>
                    <div className="lines" />
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="judges-part">
                          <div className="judges-part__img">
                            <img src="assets/images/pp.jpg" alt="profile" />
                          </div>
                          <div className="judges-part__title">
                            <h3>Dibyesh Giri</h3>
                            <p>IT Entrepreneur</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="judges-part">
                          <div className="judges-part__img">
                            <img src="assets/images/judge-4.png" alt="profile" />
                          </div>
                          <div className="judges-part__title">
                            <h3>Sweta Karna</h3>
                            <p>VP of Data at Cedar Gate Technologies </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="judges-part">
                          <div className="judges-part__img">
                            <img src="assets/images/judges/dipendra.png" alt="profile" />
                          </div>
                          <div className="judges-part__title">
                            <h3>Deependra Bajracharya</h3>
                            <p>Business Development Manager at Microsoft </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="judges-part">
                          <div className="judges-part__img">
                            <img src="assets/images/judge-3.png" alt="profile" />
                          </div>
                          <div className="judges-part__title">
                            <h3>Mona Nyachhon</h3>
                            <p> Founder of Monal Tech </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="judges-part">
                          <div className="judges-part__img">
                            <img src="assets/images/pp.jpg" alt="profile" />
                          </div>
                          <div className="judges-part__title">
                            <h3>Manish Modi </h3>
                            <p> Managing Director of Khalti</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="judges-part">
                          <div className="judges-part__img">
                            <img src="assets/images/pp.jpg" alt="profile" />
                          </div>
                          <div className="judges-part__title">
                            <h3>Santosh Gartaula</h3>
                            <p>Co-founder of BikasUdhyami.com </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                        <div className="judges-part">
                          <div className="judges-part__img">
                            <img src="assets/images/pp.jpg" alt="profile" />
                          </div>
                          <div className="judges-part__title">
                            <h3>Rajan Shakya </h3>
                            <p> Vodoo</p>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-3">
                        <div className="judges-part">
                          <div className="judges-part__img">
                            <img src="assets/images/judge-5.png" alt="profile" />
                          </div>
                          <div className="judges-part__title">
                            <h3>Dipesh karki</h3>
                            <p>Economist Consultant </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="judges-part">
                          <div className="judges-part__img">
                            <img src="assets/images/judge-1.png" alt="profile" />
                          </div>
                          <div className="judges-part__title">
                            <h3>Pravin Raj Joshi</h3>
                            <p> CTO of Rooster Logic</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="judges-part">
                          <div className="judges-part__img">
                            <img src="assets/images/judge-2.png" alt="profile" />
                          </div>
                          <div className="judges-part__title">
                            <h3>Ranjan Shrestha </h3>
                            <p>CEO of Real Time Solutions &amp; CTO of Bhoos.com</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{/*judges*/}
            </div>
          </div>
        </section> {/* announcing */}
        <div className="collaborators">
          <div className="mid-part">
            <div className="mid-part__header">
              <h2>Past Collaborators</h2>
              <div className="lines" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img collab-img-sunway">
                    <img src="assets/images/sunway.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img">
                    <img src="assets/images/kayo.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img">
                    <img src="assets/images/tootle.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img">
                    <img src="assets/images/vianet.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img collab-img-cake-koseli">
                    <img src="assets/images/cake-koseli.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img">
                    <img src="assets/images/gorkha tea.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img collab-img-techwebinar">
                    <img src="assets/images/techwebinar.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img">
                    <img src="assets/images/ict.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img collab-img-sunway">
                    <img src="assets/images/sajilo.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img">
                    <img src="assets/images/wave.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img">
                    <img src="assets/images/npcert.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                  <div className="collab-img">
                    <img src="assets/images/nepal.ai.png" alt="" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/*collaborators*/}
        {/* lightbox */}
        <div className='lightbox-container'>
          {isOpen && memories.length && (
            <Lightbox
              mainSrc={memories[photoIndex].url}
              nextSrc={memories[(photoIndex + 1) % memories.length].url}
              prevSrc={memories[(photoIndex + memories.length - 1) % memories.length].url}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + memories.length - 1) % memories.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % memories.length,
                })
              }
            />
          )}
        </div>
      </>
    )
  }
}

export default About
