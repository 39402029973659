import React, { Component } from 'react'

export const ContactFooter = () => {
    return (
        <>

            <footer className="site-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="site-footer-content col-md-6 col-12 d-flex align-items-center justify-content-md-start justify-content-center">
                            <div className="site-footer-logo">
                                <a href='https://www.spiralogics.com/' target='_blank'>
                                    <img src="assets/images/logo.svg" alt="spiralogic logo" />
                                </a>
                            </div>
                            <p> © 2025 Spirathon | Official Event of Spiralogics</p>
                        </div> {/* col */}
                        <div className="col-md-6 col-12">
                            <div className="social-media">
                                <ul>
                                    <li> <a href="https://www.facebook.com/spiralogicsintl" target='_blank'> <img src="assets/images/facebook.svg" /> </a> </li>
                                    <li> <a href="https://www.youtube.com/@lifeatspiralogics" target='_blank'> <img src="assets/images/youtube.svg" /> </a> </li>
                                    <li> <a href="https://www.linkedin.com/company/spiralogics-inc./mycompany" target='_blank'> <img src="assets/images/linkedin.svg" /> </a> </li>
                                    <li> <a href="https://www.instagram.com/spiralogicsinternational/" target='_blank'> <img src="assets/images/instagram.svg" /> </a> </li>
                                </ul>
                            </div>
                        </div> {/* col */}
                    </div>
                </div> {/* container-fluid*/}
            </footer>
        </>
    )
}