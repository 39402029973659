import {
  TEAM_REGISTER_SUCCESS,
  TEAM_REGISTER_ERROR,
  TEAM_SAVE_DRAFT_SUCCESS,
  TEAM_SAVE_DRAFT_ERROR,
  SENDING_TEAM_REGISTRATIN_FORM
} from '../actions/actionTypes'

const initialState = {
  teamError: null,
  formDisabled: false,
  sendingForm: false,
}
const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case SENDING_TEAM_REGISTRATIN_FORM:
    return {
      ...state,
      sendingForm: true
    }

    case TEAM_REGISTER_SUCCESS:
      //console.log('team registration successful')
      localStorage.setItem('registered', true)
      return {
        ...state,
        sendingForm: false,
        teamError: null
      }

    case TEAM_REGISTER_ERROR:
      //console.log('error registering the team')
      return {
        ...state,
        sendingForm: false,
        teamError: action.err
      }

    case TEAM_SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        sendingForm: false,
        teamError: null
      }
    
    case TEAM_SAVE_DRAFT_ERROR:
      return {
        ...state,
        sendingForm:false,
        teamError: action.err
      }
    default:
      return state
  }
}

export default teamReducer
