import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import Proptypes from 'prop-types'
import { signOut } from '../../store/actions/authActions'
import { getfromLocalStorage } from '../../shared/shared'

class SignedInFooter extends Component {
  constructor(props) {
    super(props)
  }
  callLogout = e => {
    this.props.logout()

  }

  render() {
    return (
      <div className='col col-1-4 clearfix fr'>
        <div className='footer-btn'>
          <Link to={this.props.registered ? `/team-view/${this.props.id}` : `/team/edit/${this.props.id}`} className='btn login-btn'>
            {/* <img src='/assets/images/icons/register.png' alt='email button' /> */}
            <label>Dashboard</label>
          </Link>

          <Link onClick={this.callLogout} className='btn register-btn iamnotunderlined' to='/'>
            {/* <img src='/assets/images/icons/login.png' alt='logout button' /> */}
            <label>
              Logout
              </label>
          </Link>

        </div>
      </div>
    )
  }
}

SignedInFooter.propTypes = {
  logout: Proptypes.func.isRequired
}

const mapStateToProps = state => {
  //console.og(state)
  return {
    email: state.auth.email,
    id: state.auth.userId,
    registered: state.auth.registered
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedInFooter)
