import authReducer from './authReducer'
import { reducer as formReducer } from 'redux-form'
import { combineReducers } from 'redux'
import teamReducer from './userReducer'
import NotificationsReducer from './notifyReducer'
const rootReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  team: teamReducer,
})

export default rootReducer
