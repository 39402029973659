import { createStore, applyMiddleware,compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../store/reducers/rootReducer'

const isDev = process.env.NODE_ENV === 'development';
const enhancers = [];
if (isDev && window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

export default function configureStore () {
  return createStore(
    rootReducer,
    compose(applyMiddleware(thunk), ...enhancers)
  );
}
