import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { checkFormDisabled, signOut } from "../../store/actions/authActions";
import { connect } from "react-redux";

class HomePage extends Component {

  componentDidMount() {
    // Parse the query parameters from the URL
    const searchParams = new URLSearchParams(this.props.location.search);
    const resetSuccess = searchParams.get('reset_success');

    console.log('Reset success:', resetSuccess, searchParams);
    console.log('Reset_success')

    if (resetSuccess === 'true') {
      this.props.signOut(this.props.history)
    }

    this.props.checkRegistrationClosed()
  }


  render() {
    console.log(this.props.isRegistrationClosed)
    return (
      <main className="home">
        <section className="announcing-wrapper">
          <div className="container-fluid">
            <div className="announcing">
              <div className="announcing__heading">
                <div className="announcing__top">
                  <div className="title">Announcing the event launch of <span>2025</span></div>
                  <h1>SPIRATHO<span>N</span></h1>
                  <div className="title">
                    {/* Public Data<span className="title-light">Innovation</span> */}
                  </div>
                </div>
              </div>
              <div className="announcing__comming-soon">
                <h4>Coming Soon...</h4>
              </div>
              <div className="announcing__content mt-2">
                <p> New year, new theme, <br />
                  and new format!
                </p>
              </div>
              <div className="button-wrap">
                {/* {
                  this.props.isRegistrationClosed ?
                    <button to="" className="common-btn btn-disabled" disabled>REGISTRATION CLOSED</button>
                    :
                    <NavLink to="/signup" className="common-btn">REGISTER YOUR TEAM</NavLink>
                } */}
                <NavLink to="/contact-us" className="common-btn contact-btn">CONTACT US</NavLink>
                {/* <a href="" className="common-btn"> Register YOUR TEAM</a>
              <a href="" className="common-btn contact-btn"> CONTACT US</a> */}
              </div>
            </div>
          </div>
        </section>

        {/*
        <section className="announcing-wrapper">
          <div className="container-fluid">
            <div className="announcing">
              <div className="announcing__heading">
                <div className="announcing__top">
                  <div className="title">
                    Winner of <span>2024</span>
                  </div>
                  <h1>
                    SPIRATHO<span>N</span>
                  </h1>
                   <div class="title">Public Data<span class="title-light">Innovation</span></div> 
                </div>
                <div className="winner-title">
                  <strong>Team Name - </strong>
                  <span>Opinix</span>
                </div>
                <div
                  className="modal banner-modal fade"
                  id="modalToggle"
                  aria-hidden="true"
                  aria-labelledby="modalToggleLabel"
                  tabIndex={-1}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-title">
                        Project Title: Opinix - Uncover the Pulse of Customer
                        Sentiments
                      </div>
                      <div className="modal-body modal-body-banner">
                        <img
                          src="assets/images/024_teams/winner.jpg"
                          alt="Hover Image"
                          width="400px"
                        />
                        <div className="project-content">
                          <p>
                            <b>Project Description:</b> Interface that visually
                            depicts customer sentiments on online products, utilizing
                            techniques like LDA and integrating them with Django
                            backend to extract keywords, analyze reviews, and present
                            results in an engaging manner.{" "}
                          </p>
                          <p>
                            <b>Members:</b> Rajendra Khanal, Parikshit Adhikari, and
                            Prayag Raj Acharya
                          </p>
                          <p>
                            <b>Mentor:</b> Prati Shrestha, Sr. QA Analysts at
                            Spiralogics, Inc.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="common-btn img-btn"
                  data-bs-target="#modalToggle"
                  data-bs-toggle="modal"
                >
                  {" "}
                  Winner Snap
                </button>
              </div>
              <div className="announcing__comming-soon mt-2">
                <h3>See you in</h3>
                <h4>SPIRATHON 2025</h4>
              </div>
              <div className="announcing__content">
                <p> New year, new theme, and new format!</p>
              </div>

            </div>
          </div>
        </section>
        */}
        {" "}
        {/* announcing */}

      </main >
    )
  }
};

const mapStateToProps = state => {
  return {
    isRegistered: state.auth.registered,
    isRegistrationClosed: state.auth.formDisabled,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    signOut: (history) => dispatch(signOut(history)),
    checkRegistrationClosed: () => dispatch(checkFormDisabled())
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
